import React, { FC } from "react";

import { StyledDropdown, Container, Title } from "./styles";

interface IOptions {
  value: string;
  label: string;
}

interface IProps {
  onChange: (param: IOptions) => void;
  value?: IOptions;
  options: Array<IOptions>;
  title?: string,
  horizontal?: boolean;
  isDisabled?: boolean;
  width?: number;
  containerStyle?: any
}

const DropdownComponent: FC<IProps> = ({ onChange, value, options, title, horizontal, isDisabled, width, containerStyle }) => {
  return <Container style={containerStyle}  {...{ horizontal }}>
    {title && <Title {...{ horizontal }}>
      {title}
    </Title>}
    <StyledDropdown
      {...{
        width,
        isDisabled,
        value,
        onChange,
        options
      }}
    />
  </Container>;
};

export default DropdownComponent;
