import { initialState } from "./state";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Statistics from "../../../types/models/statistics";
import { FetchDataErrorPayload, TStatisticsPayload } from "./types";

const appSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    // fetch
    fetchDataTrigger(state, _action: PayloadAction<TStatisticsPayload>) {
      state.fetch.loading = true;
      state.fetch.done = false;
      state.fetch.error = null;
      state.data = null;

    },
    fetchDataSuccess(state, action: PayloadAction<Statistics>) {
      state.fetch.done = true;
      state.data = action.payload;
    },
    fetchDataFailed(state, action: PayloadAction<FetchDataErrorPayload>) {
      state.fetch.error = action.payload;
    },
    fetchDataFulfilled(state) {
      state.fetch.loading = false;
      state.fetch.done = false;
    },
  },
});

export const { actions, reducer, name } = appSlice;
