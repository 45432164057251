import styled from "styled-components/macro";

const StyledButton = styled.button`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  margin-top: 2px;

  :hover {
    background-color: #3bafe5;
  }
`;

export { StyledButton };
