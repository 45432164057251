import { call, put } from "redux-saga/effects";
import { actions as dataTableActions } from "./slice";
import * as dataTableServices from "./services";

/**
 * Fetch data table request/response handler
 */
export function* fetchDataTableSaga({
  payload,
}: ReturnType<typeof dataTableActions.fetchDataTrigger>) {
  try {
    const { data } = yield call(dataTableServices.getData, payload);

    yield put(dataTableActions.fetchDataSuccess(data));
  } catch (err: any) {
    yield put(dataTableActions.fetchDataFailed(err));
  } finally {
    yield put(dataTableActions.fetchDataFulfilled());
  }
}
