import styled from "styled-components/macro";
import mq from "../../utils/mediaQueriesBp";
import DatePicker from "react-date-picker";

const StyledDatePicker = styled(DatePicker)<{ disabled?: boolean }>`
  margin-bottom: 5px;
  margin-right: 15px;
  div {
    border-radius: 7px;
    border-color: #929ca0;
    ${mq("laptop")} {
      width: 308px;
    }
    input,
    span {
      color: ${({ disabled }) => (disabled ? "#929ca0" : "#000")};
    }
    .react-date-picker__button .react-date-picker__button__icon {
      stroke: ${({ disabled }) => (disabled ? "#929ca0" : "#000")};
    }
  }
`;

export { StyledDatePicker };
