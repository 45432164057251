import styled from "styled-components/macro";
import mq from "./utils/mediaQueriesBp";
import { Line } from "react-chartjs-2";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${mq("desktop")} {
    flex-direction: column;
  }
`;
const Chart = styled(Line)`
  padding: 10px 50px 20px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const DropdownContainer = styled.div``;
const Image = styled.img`
  width: 250px;
  height: 250px;
`;
const Title = styled.p`
  font-weight: 900;
  font-size: 20px;
  color: #929ca0;
`;

const Button = styled.button`
  width: 200px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 10px;
  border-width: 1px;
  background-color: #3bafe5;
  color: #fff;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: 0;
  ${mq("desktop")} {
    margin-left: 30px;
  }
`;

const SideBar = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;

  padding: 24px 24px 0;
  ${mq("desktop")} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  justify-content: flex-start;
  margin: 27px 10px 0px;
`;

const Container = styled.div`
  width: 100%;
  padding-top: 15px;
`;

const DateRow = styled.div`
  ${mq("laptop")} {
    flex-direction: column;
  }
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
`;

const TopDatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const DatePickerContainer = styled.div`
  padding-left: 25px;
  padding-right: 30px;
  z-index: 2;
  width: 100vw - 25px;
  height: 120px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mq("laptop")} {
    height: 230px;
    flex-direction: column;
    justify-content: center;
  } ;
`;

const ExportContainer = styled.div`
  display: flex;
  flex-direction: "row";
  justify-content: center;
  align-items: center;
  width: 300px;
`;

const StyledP = styled.p`
  text-align: left;
  margin-left: 5px;
`;

const StyledPBold = styled.p`
  text-align: left;
  margin-left: 5px;
  font-weight: 700;
`;

const StyledLabel = styled.p`
  text-align: left;
  margin-left: 55px;
  /* margin-bottom: -25px; */
`;

const ChartContainer = styled.div`
  position: relative;
  height: 360px;
  width: 1002px;
  display: flex;
  align-items: "center";
  ${mq("desktop")} {
    width: 900px;
  }
  ${mq("laptop")} {
    width: 550px;
  }
  ${mq("tablet")} {
    width: 375px;
  } ;
`;

const NoDataText = styled.p`
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
`;

const ChannelIdContainer = styled.div`
  padding-top: 0px;
  margin-top: -15px;
  display: flex;
  ${mq("desktop")} {
    padding-top: 25px;
    margin-top: -5px;
  } ;
`;

const RowBeforeChart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 48px;
  margin-top: 20px;
`;

const RangeButtonsContainer = styled.div``;

const TimeRangeButton = styled.button``;

const SpinnerContainer = styled.div`
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export {
  Wrapper,
  DropdownContainer,
  Image,
  Title,
  Button,
  ButtonContainer,
  SideBar,
  Chart,
  Container,
  DatePickerContainer,
  Row,
  TopDatePickerContainer,
  DateRow,
  ExportContainer,
  StyledP,
  StyledPBold,
  StyledLabel,
  ChartContainer,
  NoDataText,
  ChannelIdContainer,
  RangeButtonsContainer,
  TimeRangeButton,
  RowBeforeChart,
  SpinnerContainer,
};
