import { call, put } from "redux-saga/effects";
import { actions as parametersActions } from "./slice";
import * as parametersServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchParametersSaga() {
  try {
    const { data } = yield call(parametersServices.getData);

    yield put(parametersActions.fetchDataSuccess(data));
  } catch (err: any) {
    yield put(parametersActions.fetchDataFailed(err));
  } finally {
    yield put(parametersActions.fetchDataFulfilled());
  }
}
