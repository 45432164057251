import React, { Dispatch, FC, SetStateAction } from "react";
import { RadioRow } from "./styles";
import RadioButton from "../RadioButton";
import DropdownComponent from "../Dropdown";
import { Container } from "./styles";
import { Title } from "./styles";
import { IOptions, TRadioType } from "types/types";
import { Channels } from "types/models/parameters";
type IProps = {
  setRadio: Dispatch<SetStateAction<TRadioType>>;
  radio: TRadioType;
  selectedChannel?: Channels;
  handleChangeChannel: (option: IOptions) => void;
  channels: Array<Channels>;
};

const DropdownCheckBox: FC<IProps> = ({
  radio,
  setRadio,
  selectedChannel,
  handleChangeChannel,
  channels,
}) => {
  return (
    <Container>
      <Title>Select TV channel</Title>
      <RadioRow>
        <RadioButton
          onChange={(e) => {
            setRadio(e);
            localStorage.setItem("selectedChannelType", "all");
          }}
          checked={radio === "all"}
          value={"all"}
          title="All"
        />
        <RadioButton
          onChange={(e) => {
            setRadio(e);
            localStorage.setItem("selectedChannelType", "custom");
          }}
          checked={radio === "custom"}
          value={"custom"}
          title="&shy;"
        />
        <DropdownComponent
          containerStyle={{ marginBottom: 0, marginRight: 0 }}
          width={200}
          isDisabled={radio === "all"}
          value={selectedChannel}
          onChange={handleChangeChannel}
          options={channels || []}
        />
      </RadioRow>
    </Container>
  );
};

export default DropdownCheckBox;
