import styled from "styled-components/macro";
import Select from "react-select";
import mq from "utils/mediaQueriesBp";

const StyledDropdown = styled(Select) <{ width?: number }>`
  width: ${({ width }) => width ? width : 308}px;
`;

const Container = styled.div<{ horizontal?: boolean }>`
  ${mq("laptop")} {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  align-items: center;
  display: flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  width: ${({ horizontal }) => horizontal ? 320 : 200} px;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 25px;
  margin-right: 15px;
`;

const Title = styled.strong<{ horizontal?: boolean }>`
  margin: ${({ horizontal }) => horizontal ? 'auto' : 0};
  padding: 5px 5px 5px 0px;
`;


export { StyledDropdown, Container, Title };
