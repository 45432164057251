import styled from "styled-components";

export const RadioRow = styled.div`
    width: 300px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    `;

export const Container = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-right: 15px;
`;

export const Title = styled.strong<{ horizontal?: boolean }>`
    text-align: start;
    margin: ${({ horizontal }) => horizontal ? 'auto' : 0};
    padding: 5px;
`;