import React from "react";

import { StyledButton } from "./styles";

interface IProps {
  onClick: () => void;
}

const ExportButton = ({ onClick }: IProps) => {
  return (
    <StyledButton onClick={onClick}>
      <i className="fa fa-floppy-o" aria-hidden="true" />
    </StyledButton>
  );
};

export default ExportButton;
