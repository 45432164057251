import { takeLatest } from "@redux-saga/core/effects";
import { fetchParametersSaga } from "./modules/parameters/saga";
import { actions as parametersActions } from "./modules/parameters/slice";
import { fetchStatisticsSaga } from "./modules/statistics/saga";
import { actions as statisticsActions } from "./modules/statistics/slice";
import { fetchDataTableSaga } from "./modules/dataTable/saga";
import { actions as dataTableActions } from "./modules/dataTable/slice";

export function* watchSagas() {
  yield takeLatest(
    parametersActions.fetchDataTrigger.type,
    fetchParametersSaga
  );
  yield takeLatest(
    statisticsActions.fetchDataTrigger.type,
    fetchStatisticsSaga
  );
  yield takeLatest(dataTableActions.fetchDataTrigger.type, fetchDataTableSaga);
}
