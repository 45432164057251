import React from "react";
import logo from "../../assets/newlogo.png";
import { Container, Image, Text, TextWrapper, SecondText } from "./styles";

const Logo = () => {
  return (
    <Container>
      <Image src={logo} alt="Logo" />
      <TextWrapper>
        <Text>Analytics</Text>
        <SecondText href="https://www.strimm.us/analytics">
          How It Works
        </SecondText>
      </TextWrapper>
    </Container>
  );
};

export default Logo;
