import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
const TextWrapper = styled.div``;

const Text = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
  width: 120px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: black;
`;

const SecondText = styled.a`
  font-weight: 400;
  font-size: 12px;
  color: rgb(232, 168, 147);
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
`;

export { Container, Text, SecondText, Image, TextWrapper };
