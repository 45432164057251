import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { Default as DefaultSpinner } from "react-awesome-spinners";
import isLink from "./helpers/isLink";
import { actions as parametersActions } from "../src/store/modules/parameters/slice";
import { selectParametersData } from "../src/store/modules/parameters/selectors";
import { actions as statisticsActions } from "../src/store/modules/statistics/slice";
import {
  selectStatisticsData,
  selectStatisticsLoading,
} from "../src/store/modules/statistics/selectors";

import DatePicker from "./components/DatePicker";
import "./App.css";
import {
  Wrapper,
  SideBar,
  Chart,
  Container,
  DatePickerContainer,
  Row,
  TopDatePickerContainer,
  DateRow,
  ExportContainer,
  StyledP,
  StyledLabel,
  ChartContainer,
  NoDataText,
  ChannelIdContainer,
  RangeButtonsContainer,
  TimeRangeButton,
  StyledPBold,
  RowBeforeChart,
  SpinnerContainer,
} from "./styles";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

import Logo from "./components/Logo";
import DataComponent from "./components/DataComponent";
import { IOptions, TRadioType } from "types/types";
import DropdownComponent from "./components/Dropdown";
import DropdownCheckBox from "./components/DropdownCheckBox";
import ExportButton from "./components/ExportButton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const dateSelect: Array<IOptions> = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Yesterday",
    value: "yesterday",
  },
  {
    label: "Last 7 Days",
    value: "last7days",
  },
  {
    label: "This month",
    value: "thisMonth",
  },
  {
    label: "Last month",
    value: "lastMonth",
  },
  {
    label: "Custom range",
    value: "custom",
  },
];

function App() {
  const dispatch = useDispatch();

  const parametersData = useSelector(selectParametersData);
  const statisticsData = useSelector(selectStatisticsData);
  const statisticsDataLoading = useSelector(selectStatisticsLoading);

  const [radio, setRadio] = useState<TRadioType>("all");

  const [selectedDateType, setSelectedDateType] = useState<IOptions>(
    dateSelect[2]
  );

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [selectedSource, setSelectedSource] = useState(
    parametersData?.data?.sources[0]
  );
  const [selectedAction, setSelectedAction] = useState(
    parametersData?.data?.actions[0]
  );

  const [selectedChannel, setSelectedChannel] = useState(
    parametersData?.data?.channels[0]
  );
  const [selectedExportType, setSelectedExportType] = useState(
    parametersData?.data.exports[0]
  );

  const [selectedGroupType, setSelectedGroupType] = useState("");

  const [chartOffset, setChartOffset] = useState(false);

  let channelIDs: Array<string> = [];
  let sourceValues: Array<string> = [];

  parametersData?.data.channels.forEach(
    (channel: IOptions) =>
      channel.value !== "all" && channelIDs.push(channel.value)
  );

  parametersData?.data.sources.forEach(
    (source: IOptions) =>
      source.value !== "all" && sourceValues.push(source.value)
  );

  useEffect(() => {
    dispatch(parametersActions.fetchDataTrigger());
  }, [dispatch]);

  useEffect(() => {
    if (parametersData) {
      //source
      // if (localStorage.getItem("selectedSource")) {
      //   setSelectedSource(
      //     parametersData?.data?.sources.find(
      //       (item) => item.label === localStorage.getItem("selectedSource")
      //     )
      //   );
      // } else {
      setSelectedSource(parametersData?.data?.sources[0]);
      // }

      //action
      if (localStorage.getItem("selectedAction")) {
        setSelectedAction(
          parametersData?.data?.actions.find(
            (item) => item.label === localStorage.getItem("selectedAction")
          )
        );
      } else {
        setSelectedAction(parametersData?.data?.actions[0]);
      }

      //channel
      // if (
      //   localStorage.getItem("selectedChannel") &&
      //   parametersData?.data?.channels.findIndex(
      //     (item) => item.label === localStorage.getItem("selectedChannel")
      //   ) !== -1
      // ) {
      //   setSelectedChannel(
      //     parametersData?.data?.channels.find(
      //       (item) => item.label === localStorage.getItem("selectedChannel")
      //     )
      //   );
      // } else {
      //   setSelectedChannel(parametersData?.data?.channels[0]);
      // }

      //channelType
      setRadio("all");
      // if (localStorage.getItem("selectedChannelType") === "all") {
      //   setRadio("all");
      // } else {
      //   setRadio("custom");
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametersData]);

  useEffect(() => {
    if (
      radio !== "all" &&
      parametersData &&
      parametersData?.data?.channels.length > 0
    ) {
      setSelectedChannel(parametersData?.data?.channels[0]);
    }
  }, [parametersData, radio]);

  useEffect(() => {
    setSelectedGroupType("");
    if (parametersData) {
      dispatch(
        statisticsActions.fetchDataTrigger({
          channels: radio === "all" ? channelIDs : selectedChannel?.value || "",
          source:
            selectedSource?.value === "all"
              ? sourceValues
              : selectedSource?.value || "",
          action:
            selectedAction?.value === "subscription_new"
              ? "subscription"
              : selectedAction?.value || "",
          user_type:
            selectedAction?.value === "subscription_new" ? "new_users" : "",
          from: moment(startDate).format("YYYY-MM-DD"),
          to: moment(endDate) /*.add(1, "days")*/
            .format("YYYY-MM-DD"),
          export: "",
          group_type:
            selectedDateType.value === "today" ||
            selectedDateType.value === "yesterday" ||
            selectedDateType.value === "thisMonth" ||
            selectedDateType.value === "lastMonth" ||
            (moment
              .duration(
                moment(endDate)
                  .endOf("day")
                  .diff(moment(startDate).startOf("day"))
              )
              .asDays() >= 1 &&
              moment
                .duration(
                  moment(endDate)
                    .endOf("day")
                    .diff(moment(startDate).startOf("day"))
                )
                .asDays() < 30)
              ? "daily"
              : "monthly",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDate,
    endDate,
    selectedAction,
    selectedSource,
    selectedChannel,
    radio,
  ]);

  useEffect(() => {
    if (isLink(statisticsData?.data)) {
      if (statisticsData?.data.link === "The data is not available") {
        toast.error(statisticsData?.data.link);
      } else window.open(statisticsData?.data.link, "_blank");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsData]);

  useEffect(() => {
    switch (selectedDateType.value) {
      case "today":
        setStartDate(getDate(0));
        setEndDate(getDate(0));
        break;
      case "yesterday":
        setStartDate(getDate(-1));
        setEndDate(getDate(-1));
        break;
      case "last7days":
        setStartDate(getDate(-8));
        setEndDate(getDate(-1));
        break;
      case "thisMonth":
        setStartDate(getMonth(1, 0));
        setEndDate(getDate(-1));
        break;
      case "lastMonth":
        setStartDate(getMonth(1, -1));
        setEndDate(getMonth(0, 0));
        break;
      case "custom":
        setStartDate(getDate(-366));
        setEndDate(getDate(-1));
        break;
    }
  }, [selectedDateType]);

  const handleExport = () => {
    if (selectedExportType) {
      dispatch(
        statisticsActions.fetchDataTrigger({
          channels: radio === "all" ? channelIDs : selectedChannel?.value || "",
          source:
            selectedSource?.value === "all"
              ? sourceValues
              : selectedSource?.value || "",
          action:
            selectedAction?.value === "subscription_new"
              ? "subscription"
              : selectedAction?.value || "",
          user_type:
            selectedAction?.value === "subscription_new" ? "new_users" : "",
          from: moment(startDate).format("YYYY-MM-DD"),
          to: moment(endDate) /*.add(1, "days")*/
            .format("YYYY-MM-DD"),
          export: selectedExportType?.value,
          group_type:
            selectedDateType.value === "today" ||
            selectedDateType.value === "yesterday" ||
            selectedDateType.value === "thisMonth" ||
            selectedDateType.value === "lastMonth" ||
            (moment
              .duration(
                moment(endDate)
                  .endOf("day")
                  .diff(moment(startDate).startOf("day"))
              )
              .asDays() >= 1 &&
              moment
                .duration(
                  moment(endDate)
                    .endOf("day")
                    .diff(moment(startDate).startOf("day"))
                )
                .asDays() < 30)
              ? "daily"
              : "monthly",
        })
      );
    } else {
      toast.error("Export type is not selected!");
    }
  };

  const handleChangeSource = (option: IOptions) => {
    setSelectedSource(option);
    console.log(`Source selected:`, option);
    localStorage.setItem("selectedSource", option.label);
  };
  const handleChangeUserType = (option: IOptions) => {
    setSelectedAction(option);
    console.log(`Action selected:`, option);
    localStorage.setItem("selectedAction", option.label);
  };
  const handleChangeChannel = (option: IOptions) => {
    setSelectedChannel(option);
    console.log(`Channel selected:`, option);
    localStorage.setItem("selectedChannel", option.label);
  };

  const getDate = (day: number) =>
    new Date(new Date().setDate(new Date().getDate() + day));

  const getMonth = (day: number, month: number) => {
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + month, day);
  };

  // const handleHourClick = () => {
  //   setSelectedGroupType("hourly");
  //   if (parametersData) {
  //     dispatch(
  //       statisticsActions.fetchDataTrigger({
  //         channels: radio === "all" ? channelIDs : selectedChannel?.value || "",
  //         source:
  //           selectedSource?.value === "all"
  //             ? sourceValues
  //             : selectedSource?.value || "",
  //         action:
  //           selectedAction?.value === "subscription_new"
  //             ? "subscription"
  //             : selectedAction?.value || "",
  //         user_type:
  //           selectedAction?.value === "subscription_new" ? "new_users" : "",
  //         from: moment(startDate).format("YYYY-MM-DD"),
  //         to: moment(endDate)/*.add(1, "days")*/.format("YYYY-MM-DD"),
  //         export: "",
  //         group_type: "hourly",
  //       })
  //     );
  //   }
  // };
  const handleDayClick = () => {
    setSelectedGroupType("daily");
    // if (parametersData) {
    dispatch(
      statisticsActions.fetchDataTrigger({
        channels: radio === "all" ? channelIDs : selectedChannel?.value || "",
        source:
          selectedSource?.value === "all"
            ? sourceValues
            : selectedSource?.value || "",
        action:
          selectedAction?.value === "subscription_new"
            ? "subscription"
            : selectedAction?.value || "",
        user_type:
          selectedAction?.value === "subscription_new" ? "new_users" : "",
        from: moment(startDate).format("YYYY-MM-DD"),
        to: moment(endDate) /*.add(1, "days")*/
          .format("YYYY-MM-DD"),
        export: "",
        group_type: "daily",
      })
    );
    // }
  };
  const handleWeekClick = () => {
    setSelectedGroupType("weekly");
    // if (parametersData) {
    dispatch(
      statisticsActions.fetchDataTrigger({
        channels: radio === "all" ? channelIDs : selectedChannel?.value || "",
        source:
          selectedSource?.value === "all"
            ? sourceValues
            : selectedSource?.value || "",
        action:
          selectedAction?.value === "subscription_new"
            ? "subscription"
            : selectedAction?.value || "",
        user_type:
          selectedAction?.value === "subscription_new" ? "new_users" : "",
        from: moment(startDate).format("YYYY-MM-DD"),
        to: moment(endDate) /*.add(1, "days")*/
          .format("YYYY-MM-DD"),
        export: "",
        group_type: "weekly",
      })
    );
    // }
  };
  const handleMonthClick = () => {
    setSelectedGroupType("monthly");
    // if (parametersData) {
    dispatch(
      statisticsActions.fetchDataTrigger({
        channels: radio === "all" ? channelIDs : selectedChannel?.value || "",
        source:
          selectedSource?.value === "all"
            ? sourceValues
            : selectedSource?.value || "",
        action:
          selectedAction?.value === "subscription_new"
            ? "subscription"
            : selectedAction?.value || "",
        user_type:
          selectedAction?.value === "subscription_new" ? "new_users" : "",
        from: moment(startDate).format("YYYY-MM-DD"),
        to: moment(endDate) /*.add(1, "days")*/
          .format("YYYY-MM-DD"),
        export: "",
        group_type: "monthly",
      })
    );
    // }
  };

  ////////////TODO: UPDATE CHART////////////////

  let stData;

  stData = statisticsData?.data?.items?.data;

  if (statisticsData?.data && stData) {
    stData = stData.map((element) => {
      if (selectedGroupType && selectedGroupType.length) {
        return {
          x:
            selectedGroupType === "monthly"
              ? moment(element.original_date).format("MMM, YYYY")
              : selectedGroupType === "weekly"
              ? `${moment(element.original_date).format(
                  "MMM D, YYYY"
                )} - ${moment(element.original_date)
                  .add(6, "days")
                  .format("MMM D, YYYY")}`
              : selectedGroupType === "daily"
              ? moment(element.original_date).format("MMM D, YYYY")
              : moment(element.original_date).format("MMM D, YYYY hh:mm a"),

          y:
            selectedAction?.label === "Average watch time"
              ? element.average_count
              : element.count,
        };
      }

      return {
        x:
          moment.duration(moment(endDate).diff(moment(startDate))).asDays() > 30
            ? moment(element.original_date).format("MMM, YYYY")
            : moment
                .duration(moment(endDate).diff(moment(startDate)))
                .asDays() <= 30 &&
              moment
                .duration(moment(endDate).diff(moment(startDate)))
                .asDays() >= 1
            ? moment(element.original_date).format("MMM D, YYYY")
            : moment(element.original_date).format("MMM D, YYYY hh:mm a"),

        y:
          selectedAction?.label === "Average watch time"
            ? element.average_count
            : element.count,
      };
    });
  }

  useEffect(() => {
    if (stData && stData.length === 1) setChartOffset(true);
    else setChartOffset(false);
  }, [stData]);

  const chartRef = useRef();

  const chartData = {
    type: "line",
    datasets: [
      {
        label: radio !== "all" ? selectedChannel?.label : "All channels",
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(233, 242, 247, 0.6)",
        data: stData?.length ? stData : [],
        fill: true,
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position:
          window.screen.width > 768 ? ("right" as const) : ("bottom" as const),
      },
      tooltip: {
        callbacks: {
          label: (v) => formatLabelsAsMins(v),
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (v) =>
            selectedAction?.label === "Average watch time"
              ? formatSecsAsMins(v)
              : v,
          precision: 0,
        },
      },
      x: {
        offset: chartOffset,
      },
    },
  };

  const formatLabelsAsMins = (context) => {
    let label = context.dataset.label || "";

    if (label) {
      label += ": ";
    }
    if (context.parsed.y !== null) {
      if (selectedAction?.label === "Average watch time") {
        label += moment.utc(context.parsed.y * 1000).format("HH:mm:ss");
      } else {
        label += context.parsed.y;
      }
    }
    return label;
  };

  const formatSecsAsMins = (seconds) => {
    return moment.utc(seconds * 1000).format("HH:mm:ss");
  };

  ///////////////////////////////////////////////
  return (
    <Wrapper className="App">
      <DatePickerContainer>
        <Logo />
        <TopDatePickerContainer>
          <DropdownComponent
            containerStyle={{ marginBottom: 5 }}
            horizontal
            title="Date range"
            value={selectedDateType}
            onChange={setSelectedDateType}
            options={dateSelect || []}
          />
          <DateRow>
            <DatePicker
              disabled={selectedDateType.value !== "custom"}
              title="Period from"
              onChange={setStartDate}
              value={startDate}
              maxDate={
                selectedDateType.value === "today" ? getDate(0) : getDate(-1)
              }
            />

            <DatePicker
              disabled={selectedDateType.value !== "custom"}
              title="to"
              onChange={setEndDate}
              value={endDate}
              maxDate={
                selectedDateType.value === "today" ? getDate(0) : getDate(-1)
              }
            />
          </DateRow>
        </TopDatePickerContainer>
      </DatePickerContainer>
      <Row>
        <SideBar>
          <DropdownComponent
            width={300}
            title="Select platform to analyze data"
            value={selectedSource}
            onChange={handleChangeSource}
            options={parametersData?.data.sources || []}
          />
          <DropdownCheckBox
            {...{
              setRadio,
              radio,
              selectedChannel,
              handleChangeChannel,
            }}
            channels={parametersData?.data.channels || []}
          />
          <DropdownComponent
            width={300}
            title="Select graphical view"
            value={selectedAction}
            onChange={handleChangeUserType}
            options={parametersData?.data.actions || []}
          />

          <ExportContainer>
            <DropdownComponent
              width={250}
              title="Export data"
              value={selectedExportType}
              onChange={setSelectedExportType}
              options={parametersData?.data.exports || []}
            />
            <ExportButton onClick={handleExport} />
          </ExportContainer>

          {radio !== "all" && (
            <ChannelIdContainer>
              <StyledP>Channel ID: </StyledP>{" "}
              <StyledPBold>{selectedChannel?.value}</StyledPBold>
            </ChannelIdContainer>
          )}
        </SideBar>
        <Container>
          <DataComponent data={statisticsData?.data?.general_data?.data} />
          <RowBeforeChart>
            <StyledLabel>{selectedAction?.label}</StyledLabel>
            <RangeButtonsContainer>
              {/* {selectedAction?.label !== "Total users" &&
                selectedAction?.label !== "New users" && (
                  <TimeRangeButton onClick={handleHourClick}>
                    Hour
                  </TimeRangeButton>
                )} */}
              <TimeRangeButton onClick={handleDayClick}>Day</TimeRangeButton>
              <TimeRangeButton onClick={handleWeekClick}>Week</TimeRangeButton>
              <TimeRangeButton onClick={handleMonthClick}>
                Month
              </TimeRangeButton>
            </RangeButtonsContainer>
          </RowBeforeChart>
          <ChartContainer>
            <Chart options={options} data={chartData} ref={chartRef} />
            {statisticsDataLoading ? (
              <SpinnerContainer>
                <DefaultSpinner />
              </SpinnerContainer>
            ) : (
              stData &&
              stData.length === 0 && (
                <NoDataText>The data is not available</NoDataText>
              )
            )}
          </ChartContainer>
        </Container>
      </Row>
    </Wrapper>
  );
}

export default App;
