import React from "react";

import { StyledDatePicker } from "./styles";

interface IProps {
  onChange: (param: Date) => void;
  value: Date;
  title: string;
  disabled: boolean;
  maxDate?: Date;
}

const DatePickerComponent = ({
  onChange,
  value,
  title,
  disabled,
  maxDate,
}: IProps) => {
  return (
    <>
      <StyledDatePicker
        {...{
          onChange,
          disabled,
          value,
        }}
        maxDate={maxDate ? maxDate : new Date()}
        format={"MMM dd yyyy"}
        locale="en"
      />
    </>
  );
};

export default DatePickerComponent;
