import ApiConfig from "../../../config/api-config";
import { $apiClient } from "../../../utils/apiClient";
import moment from "moment";
import { TStatisticsPayload } from "./types";

export function getData(payload: TStatisticsPayload) {
  console.log(payload);
  const url =
    payload.from === payload.to &&
    payload.from === moment().format("YYYY-MM-DD") &&
    payload.to === moment().format("YYYY-MM-DD")
      ?  `${ApiConfig.STATISTICS_TODAY}?channels=${payload.channels}&source=${payload.source}&action=${payload.action}&user_type=${payload.user_type}&export=${payload.export}&group_type=${payload.group_type}`
      : `${ApiConfig.STATISTICS}?channels=${payload.channels}&source=${payload.source}&action=${payload.action}&user_type=${payload.user_type}&from=${payload.from}&to=${payload.to}&export=${payload.export}&group_type=${payload.group_type}`;
  return $apiClient.get(url);
}
