import { TRadioType } from '../../types/types';
import { Dispatch, FC, SetStateAction } from 'react'
import { Container } from './styles';

interface IProps {
    value: TRadioType;
    checked: boolean
    title: string;
    onChange: Dispatch<SetStateAction<TRadioType>>
}

const RadioButton: FC<IProps> = ({ title, onChange, value, checked }) => {
    const onChangeHandler = () => {
        onChange(value)
    }
    return (
        <Container>
            <input
                type="radio"
                {...{
                    value,
                    checked,
                }}
                onChange={onChangeHandler}
                name={title}
            />
            <p>{title}</p>
        </Container>
    )
}



export default RadioButton;