import { reducer as parameters } from "./modules/parameters/slice";
import { reducer as statistics } from "./modules/statistics/slice";
import { reducer as dataTable } from "./modules/dataTable/slice";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parameters,
  statistics,
  dataTable,
};
