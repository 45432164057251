import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../types/store/rootState";
import { initialState } from "./state";

const selectDomain = (state: RootState) => state.statistics || initialState;

export const selectStatisticsData = createSelector(
  [selectDomain],
  (statisticsState) => statisticsState.data
);

export const selectStatisticsLoading = createSelector(
  [selectDomain],
  (statisticsState) => statisticsState.fetch.loading
);

export const selectStatisticsDone = createSelector(
  [selectDomain],
  (statisticsState) => statisticsState.fetch.done
);

export const selectStatisticsError = createSelector(
  [selectDomain],
  (statisticsState) => statisticsState.fetch.error
);
