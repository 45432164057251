import { initialState } from "./state";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DataTable from "../../../types/models/dataTable";
import { FetchDataErrorPayload, TDataTablePayload } from "./types";

const appSlice = createSlice({
  name: "dataTable",
  initialState,
  reducers: {
    // fetch
    fetchDataTrigger(state, _action: PayloadAction<TDataTablePayload>) {
      state.fetch.loading = true;
      state.fetch.done = false;
      state.fetch.error = null;
    },
    fetchDataSuccess(state, action: PayloadAction<DataTable>) {
      state.fetch.done = true;
      state.data = action.payload;
    },
    fetchDataFailed(state, action: PayloadAction<FetchDataErrorPayload>) {
      state.fetch.error = action.payload;
    },
    fetchDataFulfilled(state) {
      state.fetch.loading = false;
      state.fetch.done = false;
    },
  },
});

export const { actions, reducer, name } = appSlice;
