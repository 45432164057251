import styled from "styled-components/macro";
import mq from "../../utils/mediaQueriesBp";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${mq("tablet")} {
    flex-direction: column;
  } ;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${mq("tablet")} {
    flex-direction: column;
  } ;
`;

const Item = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;
const RightNowItem = styled.div`
  margin-right: 50px;
  padding: 0 15px 0;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(215, 215, 215);
  width: 100%;
  justify-content:'center';
  ${mq("tablet")} {
    padding: 0 0;
  } ;
`;

const Separator = styled.div`
  width: 1px;
  height: auto;
  background-color: grey;
`;

export { Row, Item, Separator, RightNowItem, ItemContainer };
