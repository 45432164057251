import { call, put } from "redux-saga/effects";
import { actions as statisticsActions } from "./slice";
import * as statisticsServices from "./services";

/**
 * Fetch statistics request/response handler
 */
export function* fetchStatisticsSaga({
  payload,
}: ReturnType<typeof statisticsActions.fetchDataTrigger>) {
  try {
    const { data } = yield call(statisticsServices.getData, payload);

    yield put(statisticsActions.fetchDataSuccess(data));
  } catch (err: any) {
    yield put(statisticsActions.fetchDataFailed(err));
  } finally {
    yield put(statisticsActions.fetchDataFulfilled());
  }
}
