const breakpoints = {
  tablet: 480,
  laptop: 768,
  desktop: 1025,
  large: 1440,
};

const mq = (n: string) => {
  const bpArray = Object.keys(breakpoints).map((key) => [
    key,
    //@ts-ignore
    breakpoints[key],
  ]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (max-width: ${size}px)`];
    return acc;
  }, []);

  return result;
};

export default mq;
