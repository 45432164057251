import React from "react";
import moment from "moment";
import { Row, Item, Separator, RightNowItem, ItemContainer } from "./styles";

const DataComponent = ({ data }) => {
  return (
    <Row>
      {data &&
        data.length &&
        data.map(({ label, count }, index) => {
          return (
            <ItemContainer key={index}>
              {label !== "Right Now" ? (
                <>
                  <Item>
                    <p>{label}</p>
                    <strong>
                      {label === "Average watch time"
                        ? moment.utc(count * 1000).format("HH:mm:ss")
                        : count}
                    </strong>
                  </Item>
                  {index + 1 !== data.length && <Separator />}
                </>
              ) : (
                <RightNowItem>
                  <p>{label}</p>
                  <strong style={{ marginBottom: 10 }}>
                    {label === "Average watch time"
                      ? moment.utc(count * 1000).format("HH:mm:ss")
                      : count}
                  </strong>
                </RightNowItem>
              )}
            </ItemContainer>
          );
        })}
    </Row>
  );
};

export default DataComponent;
