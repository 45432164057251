import getUrlQuery from "../utils/getUrlQuery";

const ApiConfig = {
  BASE_URL: process.env.REACT_APP_API_URL,
  PARAMETERS: `api/v1/analytic/${getUrlQuery("userId")}/parameters`,
  STATISTICS: `api/v1/analytic/${getUrlQuery("userId")}/statistics`,
  STATISTICS_TODAY: `api/v1/analytic/${getUrlQuery("userId")}/statistics/today`,
  DATA_TABLE: `api/v1/analytic/${getUrlQuery("userId")}/statistics/general`,
};

export default ApiConfig;
